import { useRoute } from '#imports';

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.hook('page:finish', async () => {
    const route = useRoute();
    window.dataLayer = window.dataLayer || [];

    await nextTick();

    const oldTitle = document.title; // Uložíme si starý title - problém je že zpracování dynamických dat u useHead chvíli trvá a do té doby je zde starý title.
    const startTime = Date.now();
    const timeout = 2000;

    // Počkáme, dokud se title skutečně nezmění oproti staré hodnotě
    while ((document.title === oldTitle || !document.title.trim()) && Date.now() - startTime < timeout) {
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    window.dataLayer.push({
      event: 'nuxtRoute',
      page_path: route.fullPath,
      page_title: document.title || 'Untitled', // Pokud není title, nastavíme fallback
      page_routeName: route.name || 'Unknown', // Pokud není route name, nastavíme fallback
      page_type: 'PageView',
    });
  });
});
